<template>
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <c-card title="교육과정 기본정보" :collapsed="true" class="cardClassDetailInfo">
        <template slot="card-detail">
          <div v-if="editable && educationInfo.plantNm" class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
            <c-label-text title="사업장" :value="educationInfo.plantNm"></c-label-text>
          </div>
          <div v-if="editable && educationInfo.educationCourseName" class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
            <c-label-text title="교육과정" :value="educationInfo.educationCourseName"></c-label-text>
          </div>
          <div v-if="editable && educationInfo.machineryClassificationName" class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
            <c-label-text title="교육종류1" :value="educationInfo.educationKindCdLargeName"></c-label-text>
          </div>
          <div v-if="editable && educationInfo.educationKindCdSmallName" class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
            <c-label-text title="교육종류2" :value="educationInfo.educationKindCdSmallName"></c-label-text>
          </div>
          <div v-if="editable && educationInfo.educationTypeName" class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
            <c-label-text title="교육구분" :value="educationInfo.educationTypeName"></c-label-text>
          </div>
          <div v-if="editable && educationInfo.legalEducationFlagName" class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
            <c-label-text title="법정교육" :value="educationInfo.legalEducationFlagName"></c-label-text>
          </div>
          <div v-if="editable && educationInfo.educationTimeName" class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
            <c-label-text title="교육시간" :value="educationInfo.educationTimeName"></c-label-text>
          </div>
          <div v-if="editable && educationInfo.educationPurpose" class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
            <c-label-text title="학습목적" :value="educationInfo.educationPurpose"></c-label-text>
          </div>
          <div v-if="editable && educationInfo.relatedLawsName" class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
            <c-label-text title="관련법규" :value="educationInfo.relatedLawsName"></c-label-text>
          </div>
        </template>
      </c-card>
      <c-table
        ref="table"
        title="교육이력"
        :columns="grid.columns"
        :data="grid.data"
        :merge="grid.merge"
        rowKey="eduEducationId"
        @linkClick="linkClick"
      >
      </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
    </div>
  </div>
</template>
<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'education-history',
  props: {
    eduCourseId: {
      type: String,
      default: function() {
        return '';
      },
    },
  },
  data() {
    return {
      editable: true,
      educationInfo: {
        plantNm: '',
        educationCourseName: '',
        educationKindCdLargeName: '',
        educationKindCdSmallName: '',
        educationTypeName: '',
        legalEducationFlagName: '',
        educationPurpose: '',
        educationTimeName: '',
        relatedLawsName: '',
      },
      grid: {
        columns: [
          {
            name: 'plantNm',
            field: 'plantNm',
            label: '사업장',
            align: 'center',
            sortable: true,
          },
          {
            name: "educationName",
            field: "educationName",
            label: "교육명",
            style: 'width:250px',
            align: "left",
            sortable: true,
            type: "link",
          },
          {
            name: "educationTypeName",
            field: "educationTypeName",
            label: "교육구분",
            align: "center",
            sortable: true,
          },
          {
            name: 'educationLocation',
            field: 'educationLocation',
            label: '교육장소',
            align: 'center',
            sortable: true,
          },
          {
            name: "educationDate",
            field: "educationDate",
            label: "교육일시",
            style: 'width:250px',
            align: "center",
            sortable: true,
          },
          {
            name: "educationMethodName",
            field: "educationMethodName",
            style: 'width:90px',
            label: "교육방법",
            align: "center",
            sortable: true,
          },
          {
            name: "evaluationEvalName",
            field: "evaluationEvalName",
            style: 'width:90px',
            label: "교육평가",
            align: "center",
            sortable: true,
          },
          {
            name: "educationOverview",
            field: "educationOverview",
            style: 'width:200px',
            label: "교육총평",
            align: "left",
            sortable: true,
          },
          {
            name: "educationPurpose",
            field: "educationPurpose",
            style: 'width:200px',
            label: "학습목적",
            align: "left",
            sortable: true,
          },
        ],
        data: [],
      },
      popupOptions: {
        target: null,
        title: "",
        visible: false,
        top: "",
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
  },
  watch: {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.getUrl = selectConfig.sop.edu.course.get.url;
      // this.getStdUrl = selectConfig.sop.edu.attendee.get.url;
      this.getList();
      this.getHistoryList();
    },
    getList() {
      if (this.eduCourseId) {
        this.$http.url = this.$format(this.getUrl, this.eduCourseId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.educationInfo = this.$_.clone(_result.data);
          this.updateMode = true;
        },);
      }
    },
    getHistoryList() {
      if (this.eduCourseId) {
        this.$http.url = selectConfig.sop.edu.result.list.url
        this.$http.type = 'GET';
        this.$http.param = {
          eduCourseId: this.eduCourseId
        };
        this.$http.request((_result) => {
          // 빈값들 filter
          this.grid.data = _result.data
        },);
      }
    },
    addItem() {
      this.grid.data.splice(0, 0, {
        edcuationCourseSeq: 0,
        chkYmd: '',
        deptCd: '',
        chkResultDetail: '',
        chkResult: '',
        fileNm: '',
      })
    },
    removeItem() {
      let selectData = this.$refs['hazardEquipmentInsp'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '하나 이상 선택하세요.', // 하나 이상 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$_.forEach(selectData, item => {
          this.grid.data = this.$_.reject(this.grid.data, item)
        })
      }
    },
    /* eslint-disable no-unused-vars */
    linkClick(row, col, index) {
      this.popupOptions.title = "교육계획 상세"; // 교육계획 상세
      this.popupOptions.param = {
        eduEducationId: row.eduEducationId ? row.eduEducationId : '',
        stepCd: row.documentStatusCd === 'ESC000001' ?  'ESC0000005' : 'ESC0000010',
      };
      this.popupOptions.target = () =>
        import(`${"@/pages/sop/edu/result/educationResultDetail.vue"}`);
      this.popupOptions.visible = true;
      this.popupOptions.isFull = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup(type) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    },
  }
};
</script>