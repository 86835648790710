var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row" }, [
    _c(
      "div",
      { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" },
      [
        _c(
          "c-card",
          {
            staticClass: "cardClassDetailInfo",
            attrs: { title: "교육과정 기본정보", collapsed: true },
          },
          [
            _c("template", { slot: "card-detail" }, [
              _vm.editable && _vm.educationInfo.plantNm
                ? _c(
                    "div",
                    {
                      staticClass:
                        "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                    },
                    [
                      _c("c-label-text", {
                        attrs: {
                          title: "사업장",
                          value: _vm.educationInfo.plantNm,
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.editable && _vm.educationInfo.educationCourseName
                ? _c(
                    "div",
                    {
                      staticClass:
                        "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                    },
                    [
                      _c("c-label-text", {
                        attrs: {
                          title: "교육과정",
                          value: _vm.educationInfo.educationCourseName,
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.editable && _vm.educationInfo.machineryClassificationName
                ? _c(
                    "div",
                    {
                      staticClass:
                        "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                    },
                    [
                      _c("c-label-text", {
                        attrs: {
                          title: "교육종류1",
                          value: _vm.educationInfo.educationKindCdLargeName,
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.editable && _vm.educationInfo.educationKindCdSmallName
                ? _c(
                    "div",
                    {
                      staticClass:
                        "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                    },
                    [
                      _c("c-label-text", {
                        attrs: {
                          title: "교육종류2",
                          value: _vm.educationInfo.educationKindCdSmallName,
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.editable && _vm.educationInfo.educationTypeName
                ? _c(
                    "div",
                    {
                      staticClass:
                        "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                    },
                    [
                      _c("c-label-text", {
                        attrs: {
                          title: "교육구분",
                          value: _vm.educationInfo.educationTypeName,
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.editable && _vm.educationInfo.legalEducationFlagName
                ? _c(
                    "div",
                    {
                      staticClass:
                        "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                    },
                    [
                      _c("c-label-text", {
                        attrs: {
                          title: "법정교육",
                          value: _vm.educationInfo.legalEducationFlagName,
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.editable && _vm.educationInfo.educationTimeName
                ? _c(
                    "div",
                    {
                      staticClass:
                        "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                    },
                    [
                      _c("c-label-text", {
                        attrs: {
                          title: "교육시간",
                          value: _vm.educationInfo.educationTimeName,
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.editable && _vm.educationInfo.educationPurpose
                ? _c(
                    "div",
                    {
                      staticClass:
                        "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                    },
                    [
                      _c("c-label-text", {
                        attrs: {
                          title: "학습목적",
                          value: _vm.educationInfo.educationPurpose,
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.editable && _vm.educationInfo.relatedLawsName
                ? _c(
                    "div",
                    {
                      staticClass:
                        "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                    },
                    [
                      _c("c-label-text", {
                        attrs: {
                          title: "관련법규",
                          value: _vm.educationInfo.relatedLawsName,
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ]),
          ],
          2
        ),
        _c("c-table", {
          ref: "table",
          attrs: {
            title: "교육이력",
            columns: _vm.grid.columns,
            data: _vm.grid.data,
            merge: _vm.grid.merge,
            rowKey: "eduEducationId",
          },
          on: { linkClick: _vm.linkClick },
        }),
        _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }